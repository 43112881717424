/* eslint-disable react/prop-types */

import React  from 'react';

import { Alert } from 'react-bootstrap';

import { observer } from 'mobx-react';

const ScanSummary = observer(({orderStore, scanSettings}) => {
    if (!orderStore || orderStore.loading || !scanSettings) {
        return null;
    }

    const match = {};

    if (scanSettings.school !== 'Any') match.school = scanSettings.school;
    if (scanSettings.orderType !== 'Any') match.orderType = scanSettings.orderType;

    const counts = orderStore.countOrderStates(match);

    if (counts === null) return null;

    return (
        <Alert variant='light' className='space-above centered-box'>
            <Alert.Heading>Summary</Alert.Heading>
            <hr />
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <div className='summary-count'>Cancelled: {orderStore.cancelledOrders.length || 0}</div>
                <div className='summary-count'>Placed: {counts.placed || 0}</div>
                <div className='summary-count'>Printed: {counts.printed || 0}</div>
                <div className='summary-count'>Prepared: {counts.prepared || 0}</div>
                <div className='summary-count'>Packed: {counts.packed || 0}</div>
            </div>
        </Alert>
    );
});

export { ScanSummary };
