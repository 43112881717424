import React from 'react';
import PropTypes from 'prop-types';

import Badge    from 'react-bootstrap/Badge';

import { rgbToHexString, hexStringToRgb, rgbToHSV, hsvToRGB } from '../../utils/colour';

const wordColourCache = new Map();

const palette = [
    //'#FFEB3B', // Yellow
    hexStringToRgb('#FFC107'), // Amber
    hexStringToRgb('#FF9800'), // Orange
    hexStringToRgb('#FF5722'), // Deep orange
    hexStringToRgb('#795548'), // Brown
    hexStringToRgb('#F44336'), // Red
    hexStringToRgb('#E91E63'), // Pink
    hexStringToRgb('#9C27B0'), // Purple
    hexStringToRgb('#673AB7'), // Deep purple
    hexStringToRgb('#3F51B5'), // Indigo
    hexStringToRgb('#2196F3'), // Blue
    hexStringToRgb('#03A9F4'), // Light blue
    hexStringToRgb('#00BCD4'), // Cyan
    hexStringToRgb('#009688'), // Teal
    hexStringToRgb('#4CAF50'), // Green
    hexStringToRgb('#8BC34A'), // Light green
    hexStringToRgb('#CDDC39'), // Lime
];

function hashWord(word) {
    return [...word.toLowerCase()].reduce((p, c) => p + c.charCodeAt(0), 0) % 255;
}

class Tag extends React.Component {
    static variantForWord(word) {
        const variants = ['dark','success','danger','warning','info','primary'];
        const v = (word.charCodeAt(1) + word.charCodeAt(2)) % variants.length;

        return variants[v];
    }

    static colourForWord(str) {
        if (!str) return '#000';

        const word = str.toLowerCase();

        if (wordColourCache.has(word)) return wordColourCache.get(word);

        // Split different length words at the same points so they result in
        // similar colours based on the early groups of letters.
        const splitAt = 4;

        const cols = [
            word.substr(0, splitAt),
            word.substr(splitAt, splitAt),
            word.substr(splitAt * 2)
        ].map(col => hashWord(col));

        // First part determines the hue
        const index = Math.floor(cols[0] % palette.length);
        const hsv = rgbToHSV(palette[index]);

        // Offset the hue by the rest so more similar names are similar colours,
        // but noticeably different
        hsv.h = ((hsv.h + (cols[1] / 768) + (cols[2] / 1024)) % 1.0);

        hsv.s = 0.8;
        hsv.v = 0.8;

        const colour = rgbToHexString(hsvToRGB(hsv));
        wordColourCache.set(word, colour);

        return colour;
    }

    render() {
        const tag = this.props.value;

        return <Badge
            bg={this.props.variant || 'invalid_lskdjfsldk'}
            // If variant is light, the text will not be dark by default
            text={this.props.variant === 'light' ? 'dark' : undefined}
            style={{
                ...(this.props.style || {}),
                boxSizing: 'border-box',
                background: `${Tag.colourForWord(tag)}`,
                width: this.props.width || undefined,
            }}
            className='ms-1 p-1'
        >
            {tag}
        </Badge>;
    }
}

Tag.propTypes = {
    id    : PropTypes.string,

    value : PropTypes.string,

    width: PropTypes.string,

    /**
     * A valid Bootstrap variant. Colours will be set automatically if this prop
     * is not given.
     */
    variant : PropTypes.string,

    style: PropTypes.object,
};

export { Tag };
