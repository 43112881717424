/* eslint-disable react/prop-types */

import React  from 'react';

import { Spinner, Alert, Button } from 'react-bootstrap';

import { observer } from 'mobx-react';

const scanResultVariant = {
    ok: 'success',
    error: 'danger',
    warn: 'warning',
    'ok-warn': 'primary',
};

const scanResultHeader = {
    ok: 'OK',
    'ok-warn': 'Warning',
    error: 'Failed',
    warn: 'Check',
    ready: 'Ready',
    prep: 'Setup',
}

const ScanResult = observer(({orderStore, result, message, barcode, order, onForceScan }) => {
    const [closeWarn, setCloseWarn] = React.useState(false);

    if (!orderStore || orderStore.loading) {
        return <div style={{ textAlign: 'center', gridColumn: '1/-1', marginTop: '2em' }}>
            <span>Loading...<Spinner animation='border' variant='info' size='sm' style={{ marginLeft: '2rem'}}/></span>
        </div>
    }

    const variant = scanResultVariant[result] || 'light';

    const messages = Array.isArray(message) ? message : [message];

    return (
        <Alert variant={variant} className='space-above centered-box'>
            <Alert.Heading>
                Scan { scanResultHeader[result] }
            </Alert.Heading>
            <hr />
            <ul>
                { messages.map(e => <li key={e}>{e}</li>) }
            </ul>
            {
                order
                ? <div>
                    <strong>Order:</strong>
                    <ul>
                        <li>{order.school}</li>
                        <li>{order.customer}</li>
                        <li>{order.menuItem}</li>
                        <li>{order.state}</li>
                    </ul>
                </div>
                : null
            }
            <hr />
            {
                result === 'warn' && closeWarn !== order.id
                ? <div style={{ display:'flex', justifyContent:'space-between' }}>
                    Are you sure want to complete scanning this order?
                    <div>
                        <Button variant='info' onClick={() => setCloseWarn(order.id)} className='ms-2' >Cancel</Button>
                        <Button variant='info' onClick={() => onForceScan(barcode, order)} className='ms-2' >Yes</Button>
                    </div>
                </div>
                : null
            }
        </Alert>
    );
});

export { ScanResult };
