/* eslint-disable react/prop-types */

import React  from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';

import { UISettings } from '../contexts/UISettings';

import { OrderStore } from '../store/OrderStore';

import { useAuth } from '../hooks/auth';

import { OrderDataGrid, CancelledOrderDataGrid } from './orders/OrderDataGrid';
import { PrintLabels } from './orders/PrintLabels';

import { Nav } from './Nav';
import { Toolbar } from './Toolbar';

const OrdersInner = ({api, onError, orderDate, onChangeOrderDate }) => {
    const authStore = useAuth();
    const [selected, setSelected] = React.useState(new Set());
    const [visible, setVisible] = React.useState(null);
    const [showPage, setShowPage] = React.useState('list');

    const createOrderStore = orderDate => {
        const os = new OrderStore(api, orderDate);
        os.load().catch(err => onError(err.toString()));

        return os;
    };

    const [orderStore, setOrderStore] = React.useState(() => createOrderStore(orderDate));

    const handlePrinted = async () => {
        try {
            setShowPage('list');

            if (selected.size) {
                await orderStore.printOrdersAsync([...selected].map(e => parseInt(e, 10)));

            } else if (visible) {
                await orderStore.printOrdersAsync([...visible].map(e => parseInt(e, 10)));

            } else {
                await orderStore.printOrdersAsync(null);
            }
        } catch (err) {
            onError(err);
        }
    };

    // FIXME: Need to display cancelled orders

    return (
        <div>
            <Nav />

            <Toolbar api={api} dateDisabled={showPage !== 'list'} orderDate={orderDate} onChangeOrderDate={orderDate => {
                setOrderStore(createOrderStore(orderDate));
                onChangeOrderDate(orderDate);
            }}>
                {
                    // eslint-disable-next-line no-nested-ternary
                    showPage === 'labels'
                    ? <div style={{ display: 'flex' }}>
                        <Button variant='info' onClick={() => window.print()} className='ms-2'>
                            Print
                        </Button>
                        <Button variant='info' onClick={() => handlePrinted()} className='ms-2'>
                            Close & Mark as Printed
                        </Button>
                        <Button variant='info' onClick={() => setShowPage('list')} className='ms-2'>
                            Close & Cancel
                        </Button>
                    </div>
                    : null

                }

                {
                    // eslint-disable-next-line no-nested-ternary
                    showPage === 'cancelled'
                    ? <div style={{ display: 'flex' }}>
                        <Button variant='info' onClick={() => setShowPage('list')} className='ms-2'>
                            Close
                        </Button>
                    </div>
                    : null

                }

                <div>
                    {
                        showPage === 'list' && authStore.user.permissions.includes('generateLabels')
                            ? <>
                                <Button variant='info' onClick={() => setShowPage('labels')} className='ms-2'>
                                    Print Labels
                                </Button>
                                <Button variant='info' onClick={() => setShowPage('cancelled')} className='ms-2'>
                                    Cancelled Orders
                                </Button>
                            </>
                            : null
                    }
                </div>
            </Toolbar>

            {
                showPage === 'labels'
                ? <PrintLabels
                    orderStore={orderStore}
                    subset={selected.size ? selected : visible}
                />
                : null
            }

            {
                showPage === 'cancelled'
                ? <>
                    <h5 style={{ marginTop: '2em' }}>Cancelled Orders</h5>
                    <CancelledOrderDataGrid orderStore={orderStore} />
                </>
                : null
            }

            <OrderDataGrid
                orderStore={orderStore}
                onSelectChange={selectedRows => {
                    //selected.current = selectedRows;
                    setSelected(selectedRows);
                }}
                onFilterChange={visibleRows => {
                    setVisible(visibleRows);
                }}
                style={showPage === 'list' ? undefined : { display: 'none' }}
            />
        </div>
    );
};

const Orders = props => {
    return <UISettings.Consumer>{
        ({ pushError, orderDate, setOrderDate }) => <OrdersInner {...props} onError={pushError} orderDate={orderDate} onChangeOrderDate={setOrderDate} />
    }</UISettings.Consumer>;
}

Orders.propTypes = {
    api: PropTypes.object.isRequired,
};

export {
    Orders,
}
