import assert from 'assert';

import { makeObservable, observable, flow, action, runInAction } from 'mobx';

function dateToDMY2(d) {
    return (
        // eslint-disable-next-line prefer-template
        d.getDate().toString().padStart(2, '0')
        + '-' + (d.getMonth() + 1).toString().padStart(2, '0')
        + '-' + d.getFullYear().toString().slice(2).padStart(2, '0')
    );
}

function orderBarcode(order) {
    const dateParts = order.orderDate.split('-');

    const barcode = `${dateParts[2]}${dateParts[1]}${dateParts[0]}${order.id.toString().padStart(6, '0')}`;

    return barcode;
}

class OrderStore {
    constructor(api, orderDate) {
        this._orders = [];
        this._cancelledOrders = [];
        this._barcodeMap = new Map();

        this._orderDate = orderDate;
        this._orderIndex = new Map();

        this.loading = true;

        this._api = api;

        makeObservable(
            this,
            {
                loading: observable,
                _orders: observable,
                _cancelledOrders: observable,
                _barcodeMap: observable,

                load: flow,
                scanOrderAsync: action,
            }
        );
    }

    *load() {
        const res = yield this._api.axios.get(
            '/orders',
            { params: { orderDateDMY2: dateToDMY2(this._orderDate) }}
        );

        const orders = [], cancelled = [], barcodeMap = new Map();

        for (const order of res.data.orders) {
            if (order.cancelled) {
                cancelled.push(order);

            } else {
                this._orderIndex.set(order.id, orders.length);
                orders.push(order);
            }

            const barcode = orderBarcode(order);
            barcodeMap.set(barcode, order);
        }

        this._orders = orders;
        this._cancelledOrders = cancelled;
        this._barcodeMap = barcodeMap;
        this.loading = false;
    }

    get orderDate() {
        console.log(this._orderDate);
        return this._orderDate;
    }

    get orders() {
        return this._orders;
    }

    get cancelledOrders() {
        return this._cancelledOrders;
    }

    orderForBarcode(barcode) {
        return this._barcodeMap.get(barcode);
    }

    async scanOrderAsync(orderId, toState) {
        assert(toState === 'prepared' || toState === 'packed', 'toState must be prepared or packed');

        const res = await this._api.axios.put(
            '/orders/state',
            {
                id: orderId,
                state: toState,
            }
        );

        const orderIndex = this._orderIndex.get(orderId);
        const order = res.data;
        const barcode = orderBarcode(order);

        runInAction(() => {
            this._orders[orderIndex] = order;
            this._barcodeMap.set(barcode, order);
        });
    }

    async printOrdersAsync(orderIds = null) {
        const res = await this._api.axios.put(
            '/orders/states',
            {
                ids: orderIds || this._orders.map(e => e.id),
                state: 'printed',
            }
        );

        runInAction(() => {
            const orders = [...this._orders];

            for(const order of res.data) {
                console.log(order);
                const orderIndex = this._orderIndex.get(order.id);
                const barcode = orderBarcode(order);

                orders[orderIndex] = order;
                this._barcodeMap.set(barcode, order);
            }

            // Table only observes the entire collection so need an
            // entirely new array.
            this._orders = orders;
        });
    }


    countOrders(matchObj) {
        let count = 0;
        const keys = Object.keys(matchObj);

        const allMatch = order => {
            for (const key of keys) {
                if (order[key] !== matchObj[key]) return false;
            }

            return true;
        }

        for (const order of this._orders) {
            if (allMatch(order)) count++;
        }

        return count;
    }

    countOrderStates(matchObj) {
        const counts = {};
        const keys = Object.keys(matchObj);

        const allMatch = order => {
            for (const key of keys) {
                if (order[key] !== matchObj[key]) return false;
            }

            return true;
        }

        for (const order of this._orders) {
            if (allMatch(order)) {
                if (counts[order.state]) counts[order.state]++;
                else counts[order.state] = 1;
            }
        }

        return counts;
    }
}

export { dateToDMY2, orderBarcode, OrderStore };
