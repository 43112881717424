//import { schemaPropDesc } from './schemaText';

// This module may duplicate schemas found elsewhere.
// This is fine because the UI is often built from the schemas so we want
// full control over what is presence.
// Where possible, it is good to depend on sub parts of common schemas,
// e.g. to set the schema for an identifier field.

const userNameSchema = { type: 'string', minLength: 1, maxLength: 128 };
const userPassSchema = { type: 'string', minLength: 1, maxLength: 128 };

const userEditSchema = {
    type: 'object',
    required: ['name', 'email'],
    properties: {
        name: userNameSchema,
        email: { type: 'string', format: 'email'},
        password: {
            type: 'object',
            properties: {
                newPassword: userPassSchema,
                confirmPassword: userPassSchema,
            }
        },
        roles: { type: 'array', minItems: 1 },
    }
};

const userPassChangeSchema = {
    type: 'object',
    required: ['oldPassword', 'password'],
    properties: {
        oldPassword: userPassSchema,
        // Use custom validator for password as it's the only way to ensure
        // they both match
        password: {
            type: 'object',
            properties: {
                password: userPassSchema,
                confirmPassword: userPassSchema,
            }
        }
    }
};

export {
    userEditSchema,
    userPassChangeSchema,
};
